.event-feed {
    padding: 0;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
}

.event-feed-iframe {
    height: 100%;
    width: 100%;
}

