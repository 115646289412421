@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');

.main-header {
  background-color: #f8f8f8;
  padding: 50px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; /* Change to column layout */
  justify-content: space-between; /* Distribute space between content and logo */
  position: relative;
  min-height: 400px; /* Increased height, adjust as needed */
  width: 100%;
  overflow: hidden;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.header-logo {
  width: 200px; /* Increased size, adjust as needed */
  height: auto;
  margin-right: 20px;
  align-self: flex; /* Align to the top */
}

.header-content {
  flex-grow: 1;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%); /* Center vertically */
  overflow: hidden
}

.header-image {
  width: 50%;
  height: auto;
  position: absolute;
  right: -8%;
  top: 0;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.site-title {
  font-size: 10rem;
  margin-bottom: 6%;
  letter-spacing: 10px;
  color: #000;
  text-transform: uppercase;
  font-family: 'League Gothic', sans-serif; 
  font-weight: 100;
}

.site-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align items to the bottom */
  z-index: 1;
}

.site-nav li {
  margin: 0 20px;
}

.site-nav button {
  background: none;
  border: none;
  color: #333333;
  font-size: 3rem;
  font-family: 'League Gothic', sans-serif; 
  text-transform: uppercase;
  padding: 5px 10px;
  margin-bottom: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  letter-spacing: 2px;
}

.site-nav button:hover {
  color: #000;
  background-color: #e0e0e0;
}

.content-area {
  padding: 20px;
  background-color: white;
}



@media (max-width: 1200px) {
  .header-logo {
    width: 150px; /* Slightly smaller on mobile */
    margin-bottom: 0; /* Remove bottom margin on mobile */
    margin-top: 15px; /* Add some space from the content */
  }

  .header-content {
    flex-grow: 1;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%); /* Center vertically */
  }

  .site-title {
    letter-spacing: 6px;
    font-family: 'League Gothic', sans-serif;
    font-size: 8rem;
  }

  .site-nav ul {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .site-nav li {
    margin: 5px;
  }

  .site-nav button {
    font-size: 2rem;
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .main-header {
    flex-direction: column;
    align-items: flex-start;
    min-height: auto; /* Allow height to adjust on mobile */
    padding: 20px;
    justify-content: flex-start; /* Align items to the top on mobile */
    overflow: hidden;
  }

  .header-logo {
    width: 60px; /* Slightly smaller on mobile */
    margin-bottom: 0; /* Remove bottom margin on mobile */
    margin-top: 15px; /* Add some space from the content */
  }

  .header-content {
    position: static;
    width: 100%;
    transform: none;
    margin-top: 0; /* Remove top margin on mobile */
    z-index: 1;
  }

  .header-image {
  width: 65%;
  height: 100%;
  position: absolute;
  right: -10%;
  top: 0;
  object-fit: cover;
  object-position: top;
}

  .site-title {
    letter-spacing: 6px;
    font-family: 'League Gothic', sans-serif;
    font-size: 3rem;
    margin-bottom: 15px;
  }

  .site-nav ul {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .site-nav li {
    margin: 5px;
  }

  .site-nav button {
    font-size: 0.9rem;
    padding: 3px 8px;
  }
}
